#root {
  background: linear-gradient(#ffffff, #727272);
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Bold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Regular.ttf");
  font-weight: 400;
}



.react-tel-input {
  width: 100% !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
